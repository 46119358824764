export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'de',
  messages: {
    de: {
      welcome: 'Willkommen',
    },
    en: {
      welcome: 'Welcome',
    },
  },
}));
